import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import config from "../../Boot/config.json";
import { Link } from "react-router-dom";
import "./allInstitutions.css";
import { IoLocationSharp } from "react-icons/io5";
import { ImSearch } from "react-icons/im";

import { FaPhoneAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { AuthContext } from "../../App";
import bgImg from "../../Assets/institutionbgImage.jpg";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import NoInstituteImg from "../../Assets/noInstitutions image.jpg";

function AllInstitutions({
  data,
  deleteFn,
  paginationFn,
  totalPages,
  currentPage,
  searchQueryValue,
}) {
  const [delStatus, setDelStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const { credentials } = useContext(AuthContext);

  const handleDeleteInstitute = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          title: "custom-swal-title",
          text: "custom-swal-text",
        },
      });

      if (result.isConfirmed) {
        setDelStatus(true);
        await axios
          .delete(`${config.baseUrl}/universities/deleteInstitution/${id}`, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            console.log("Successfully deleted,", res);
            deleteFn();
            setDelStatus(false);
            Swal.fire({
              title: "Deleted",
              icon: "success",
              customClass: {
                title: "custom-swal-title",
              },
            });
          })
          .catch((err) => {
            console.log(err);
            setDelStatus(false);
            Swal.fire({
              title: "Error",
              icon: "error",
              customClass: {
                title: "custom-swal-title",
              },
            });
          });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        icon: "error",
        customClass: {
          title: "custom-swal-title",
        },
      });
    }
  };

  const handlesearchChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^A-Za-z\s]/gi, "");
    setSearchQuery(sanitizedValue);
    searchQueryValue(sanitizedValue);
  };

  let lastScroll = window.scrollY;

  const hideSearchBarFn = () => {
    const currentScroll = window.scrollY;
    setIsScrollingDown(currentScroll > lastScroll);
    lastScroll = currentScroll;
  };

  const handlePageNavigation = (selectedPage) => {
    paginationFn(selectedPage.selected + 1);
  };

  function urlEncoded(title) {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  // useEffect(() => {
  //   setFilteredData(data);
  // }, [delStatus, data, searchQuery]);

  // useEffect(() => {
  //   setSearchQuery("");
  // }, [data]);

  useEffect(() => {
    window.addEventListener("scroll", hideSearchBarFn);
    return () => window.removeEventListener("scroll", hideSearchBarFn);
  }, []);

  return (
    <div className="d-flex wr_everything position-relative">
      <img
        src={bgImg}
        alt="Background"
        className="backgroundImg"
        loading="lazy"
      />
      <div className="d-flex w-100 flex-column ">
        <div
          className={`searchBarSection w-100 ${
            isScrollingDown ? "hide" : "show test"
          }`}
        >
          <input
            type="text"
            id="search"
            onChange={handlesearchChange}
            className="searchBar rounded-3 p-2 ps-3 border-0"
            placeholder="Search By Institute Name"
            value={searchQuery}
          />
          <button
            className=" p-2 text-white rounded-3  position-absolute searchButton"
            title="Search"
            disabled
          >
            <ImSearch className="fs-5" />
          </button>
        </div>
        <div className="wr_colleges d-flex flex-column justify-content-center">
          {data.length !== 0 &&
            data.map((institution, index) => (
              <div
                className="InfoCard bg-white position-relative d-flex my-2 flex-column"
                key={index}
              >
                <div className="d-flex flex-column flex-md-row pb-4 position-relative">
                  <img
                    src={institution.instituteLogo}
                    alt="institute Logo"
                    className="instituteLogoImg"
                  />
                  {credentials.token && credentials.role === "admin" && (
                    <MdDelete
                      title="Delete Institution"
                      className="position-absolute delButton end-0 fs-4 m-1"
                      onClick={() => handleDeleteInstitute(institution._id)}
                    />
                  )}

                  <div className="ms-2">
                    <Link
                      className="text-decoration-none "
                      to={`/institute/${urlEncoded(
                        institution.instituteName
                      )}/${institution.instituteId.slice(4)}`}
                    >
                      <h5
                        className="instituteCardHeading"
                        title={`${institution.instituteName}`}
                      >
                        {institution.instituteName}
                      </h5>
                    </Link>
                    <div className="instituteShortInfo">
                      <p className="align-items-center d-flex">
                        <span>
                          <IoLocationSharp className="me-2 icnCls" />
                        </span>
                        <span className="cardInfo">{institution.address}</span>
                      </p>
                      <p className="align-items-center d-flex">
                        <span>
                          <FaPhoneAlt className="me-2 icnCls" />
                        </span>
                        <span className="cardInfo">{institution.phone}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <Link
                  to={`/institute/${urlEncoded(
                    institution.instituteName
                  )}/${institution.instituteId.slice(4)}`}
                >
                  <button
                    className="position-absolute text-white fw-bold end-0 bottom-0 border-0 viewMoreBtn rounded-3"
                    title="View More Details"
                  >
                    View More
                  </button>
                </Link>
                {/* {institution.instituteId ? (
                  <Link
                    to={`/institute/${urlEncoded(
                      institution.instituteName
                    )}/${institution.instituteId.slice(4)}`}
                  >
                    <button
                      className="position-absolute text-white fw-bold end-0 bottom-0 border-0 viewMoreBtn rounded-3"
                      title="View More Details"
                    >
                      View More
                    </button>
                  </Link>
                ) : (
                  ""
                )} */}

                {/* <Link
                  to={`/institute/${urlEncoded(institution.instituteName)}/${
                    institution._id
                  }`}
                >
                  <button
                    className="position-absolute text-white fw-bold end-0 bottom-0 border-0 viewMoreBtn rounded-3"
                    title="View More Details"
                  >
                    View More
                  </button>
                </Link> */}
              </div>
            ))}

          {data.length === 0 && (
            <div className="notFoundSection InfoCard d-flex flex-column  bg-white align-items-center justify-content-center">
              <img
                src={NoInstituteImg}
                alt="No Institutions found"
                className="NoInstFound"
              />
              <p className="text-dark fs-3 fw-bold text-center">
                Sorry!! No Institutions Found
              </p>
            </div>
          )}
          <div className="paginationComponent d-flex justify-content-center">
            {totalPages === 1 ? (
              ""
            ) : (
              <ReactPaginate
                nextLabel=">"
                previousLabel="<"
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageNavigation}
                containerClassName={
                  "pagination d-flex justify-content-center mx-auto"
                }
                activeClassName={"active"}
                forcePage={currentPage - 1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllInstitutions;
