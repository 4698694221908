import { useEffect, useContext } from "react";
import { Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import Home from "../Pages/Home";

import InstituteInfoPage from "../Components/InstituteInfoPage/InstituteInfoPage";
import LoginPage from "../Components/LoginPage/LoginPage";
import NotFoundPage from "../Components/404Page/404Page";
import FilterComponent from "../Components/All Institutions/FilterPage";

import { AuthContext } from "../App";

import AllUniversities from "../Components/Universities/AllUniversities";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function PrivateRoutes() {
  const { credentials } = useContext(AuthContext);
  return credentials.token ? <Outlet /> : <Navigate to="/blogs" />;
}

function PathConfig() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/institutions/*" element={<FilterComponent />} />
        <Route
          path="/institute/:instituteName/:id"
          element={<InstituteInfoPage />}
        />
        <Route path="/admin" element={<LoginPage />} />
        <Route path="/test" element={<AllUniversities />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default PathConfig;
