import React, { useReducer, useEffect } from "react";

const initialState = {
  test: "test",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LoadPage": {
      return { test: "tested" };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function InstituteInfoPage() {
  const [state, dispatch] = useReducer(reducer, initialState);

  // The rest of the JSX and component logic

  return (
    <div>
      <h2> {state.test}</h2>
      <button
        onClick={() => {
          dispatch({ type: "LoadPage" });
        }}
      >
        Click
      </button>
    </div>
  );
}

export default InstituteInfoPage;
