import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./addInstitute.css";
import config from "../../Boot/config.json";
import { AuthContext } from "../../App";
function AddInstitute({ handleClose }) {
  const [error, setError] = useState("");
  const [collegeStatus, setCollegeStatus] = useState(false);
  const [schoolStatus, setSchoolStatus] = useState(false);
  const [addCity, setAddCity] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    typeOfInstitute: "",
    collegeType: "",
    schoolType: "",
    ownerShip: "",
    instituteName: "",
    instituteLogo: null,
    address: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
  });
  const { credentials } = useContext(AuthContext);

  const navigate = useNavigate();

  const toTitleCase = (str) => {
    return str.replace(/\b(\w+)\b/g, function (match, word) {
      // Check if the word is within brackets
      const withinBrackets = match.match(/^\(.*\)$/);

      // If within brackets, return the word as it is
      if (withinBrackets) {
        return word;
      } else {
        // Otherwise, apply title case transformation
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      }
    });
  };

  const handleChange = async (e) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      if (name === "typeOfInstitute") {
        if (value === "College") {
          setCollegeStatus(true);
          setSchoolStatus(false);
        } else if (value === "School") {
          setSchoolStatus(true);
          setCollegeStatus(false);
        } else {
          setCollegeStatus(false);
          setSchoolStatus(false);
        }
        setFormData((prev) => ({ ...prev, typeOfInstitute: value }));
      }
      if (name === "ownerShip") {
        setFormData((prev) => ({ ...prev, ownerShip: value }));
      }
    } else if (name === "country") {
      let data = { country: value };
      try {
        const res = await axios
          .post(`${config.baseUrl}/Universities/postStateChange`, data, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            setFormData((prev) => ({ ...prev, country: value }));
            setStates(res.data.states);
            setCities(res.data.cities);
          });
      } catch (err) {
        console.log(err);
      }
      // Update formData state for "state" input
    } else if (name === "state") {
      let data = { country: formData.country, state: value };
      try {
        const res = await axios
          .post(`${config.baseUrl}/Universities/postStateChange`, data, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            setCities(res.data.cities);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
      // Update formData state for "state" input
      setFormData((prev) => ({ ...prev, state: value }));
    } else if (name === "addCity" || name === "city") {
      setFormData((prev) => ({
        ...prev,
        city: value,
      }));
    } else if (name === "pincode") {
      // Validate pincode input
      if (/^\d+$/.test(value)) {
        setFormData((prev) => ({
          ...prev,
          pincode: value,
        }));
      }
    } else {
      // For other inputs, update the formData state directly
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleImageChange = (e) => {
    const { id, files } = e.target;

    if (id === "instituteLogo") {
      setFormData((prev) => ({ ...prev, [id]: files[0] }));
    }
  };

  const handleAddCity = (e) => {
    setAddCity(e.target.checked);
  };

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();

    if (!formData.typeOfInstitute || formData.typeOfInstitute.length === 0) {
      setError("Please select a type of institute");
    } else if (!formData.instituteName || formData.instituteName.length === 0) {
      setError("Please enter institution name");
    } else if (!formData.ownerShip || formData.ownerShip.length === 0) {
      setError("Please select a valid ownership");
    }
    //  else if (!formData.instituteLogo) {
    //   setError("Please select an institution logo");
    // }
    else if (!formData.country || formData.country.length === 0) {
      setError("Select a country");
    } else if (!formData.state || formData.state.length === 0) {
      setError("please enter state");
    } else if (!formData.city || formData.city.length === 0) {
      setError("please enter city");
    } else if (!formData.pincode || formData.pincode.length === 0) {
      setError("please enter a valid pincode");
    } else {
      const myFormData = new FormData();
      myFormData.append("typeOfInstitute", formData.typeOfInstitute);
      myFormData.append("collegeType", formData.collegeType);
      myFormData.append("schoolType", formData.schoolType);
      myFormData.append("ownerShip", formData.ownerShip);
      myFormData.append("instituteName", toTitleCase(formData.instituteName));
      myFormData.append("instituteLogo", formData.instituteLogo);
      myFormData.append("address", toTitleCase(formData.address));
      myFormData.append("city", formData.city);
      myFormData.append("state", formData.state);
      myFormData.append("pincode", formData.pincode);
      myFormData.append("country", formData.country);

      try {
        axios
          .post(`${config.baseUrl}/Universities/addUniversity`, myFormData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            navigate(`/institute/${res.data.instituteName}/${res.data._id}`);
            handleClose();
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/Universities/getFormDetails`)
      .then((res) => {
        setCountries(res.data.countries);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column justify-content-center mx-auto w-100 addInstituteForm"
        >
          <h3 className="text-center formHeading">Add Institute</h3>
          {/* <p>All fields are mandatory</p> */}
          <fieldset>
            <label className="formLabel">Type Of Institution</label>
            <div className="d-flex m-2">
              <div className="me-5">
                <input
                  type="radio"
                  id="school"
                  name="typeOfInstitute"
                  value="School"
                  className="me-1 d-none customRadio "
                  onChange={handleChange}
                />
                <label htmlFor="school" className="customRadioLabel">
                  School
                </label>
              </div>
              <div className="me-5">
                <input
                  type="radio"
                  id="college"
                  name="typeOfInstitute"
                  value="College"
                  className="me-1 d-none customRadio"
                  onChange={handleChange}
                />
                <label htmlFor="college" className="customRadioLabel">
                  College
                </label>
              </div>
              <div className="me-5">
                <input
                  type="radio"
                  id="university"
                  name="typeOfInstitute"
                  value="University"
                  className="me-1 d-none customRadio"
                  onChange={handleChange}
                />
                <label htmlFor="university" className="customRadioLabel">
                  University
                </label>
              </div>
            </div>
            {error && error === "Please select a type of institute" ? (
              <p className="text-danger">{error}</p>
            ) : (
              ""
            )}
          </fieldset>

          {collegeStatus && (
            <fieldset className="d-flex flex-column">
              <label htmlFor="collegeType" className="formLabel">
                Type of the college
              </label>
              <select
                name="collegeType"
                id="collegeType"
                className="p-2 w-100"
                onChange={handleChange}
              >
                <option value="">Select the type of college</option>
                <option value="Junior College">Junior College</option>
                <option value="Engineering College">Engineering College</option>
                <option value="Medical College">Medical College</option>
                <option value="Pharmacy College">Pharmacy College</option>
                <option value="Dental College">Dental College</option>
                <option value="Law School">Law School</option>
                <option value="Business School">Business School</option>
                <option value="Degree College">Degree College</option>
                <option value="Art and Design College">
                  Art and Design College
                </option>
                <option value="Music and Performing Arts College">
                  Music and Performing Arts College
                </option>
                <option value="Agricultural College">
                  Agricultural College
                </option>
              </select>
            </fieldset>
          )}
          {schoolStatus && (
            <fieldset>
              <label htmlFor="schoolType" className="formLabel">
                Type of the School
              </label>
              <select
                name="schoolType"
                id="schoolType"
                className="p-2 w-100"
                onChange={handleChange}
              >
                <option value="">Select the type of School</option>
                <option value="State Board">State Board</option>
                <option value="CBSE">CBSE</option>
                <option value="ICSE">ICSE</option>
                <option value="Cambridge International- CIE">
                  Cambridge International- CIE
                </option>
                <option value="IB">IB</option>
              </select>
            </fieldset>
          )}

          <fieldset>
            <label className="formLabel">Ownership</label>
            <div className="d-flex m-2">
              <div className="me-5">
                <input
                  type="radio"
                  id="govt"
                  name="ownerShip"
                  value="Govt"
                  className="me-1 d-none customRadio"
                  onChange={handleChange}
                />
                <label htmlFor="govt" className="customRadioLabel">
                  Government
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="private"
                  name="ownerShip"
                  value="private"
                  className="me-1 d-none customRadio"
                  onChange={handleChange}
                />
                <label htmlFor="private" className="customRadioLabel">
                  Private
                </label>
              </div>
            </div>
          </fieldset>
          {error && error === "Please select a valid ownership" ? (
            <p className="text-danger">{error}</p>
          ) : (
            ""
          )}

          <fieldset>
            <label htmlFor="instituteName" className="formLabel">
              Institution Name
            </label>
            <input
              type="text"
              id="instituteName"
              name="instituteName"
              className="p-2 w-100"
              onChange={handleChange}
            />
            {error && error === "Please enter institution name" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset>
            <label htmlFor="instituteLogo" className="formLabel">
              Institute Logo
            </label>
            <div className="fileBox">
              {formData.instituteLogo ? (
                <p>{formData.instituteLogo.name}</p>
              ) : (
                <p>No file selected</p>
              )}
              <input
                name="instituteLogo"
                type="file"
                accept="image/*"
                className="w-100"
                id="instituteLogo"
                onChange={handleImageChange}
              />
            </div>

            {/* {error && error === "Please select an institution logo" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )} */}
          </fieldset>
          <fieldset>
            <label htmlFor="country" className="formLabel">
              Country
            </label>
            <select
              name="country"
              id="country"
              className="w-100 p-2"
              onChange={handleChange}
            >
              <option value=""> Select a Country</option>
              {countries.map((country) => (
                <option value={country}>{country}</option>
              ))}
            </select>
            {error && error === "Select a country" ? (
              <p className="text-danger">{error}</p>
            ) : (
              ""
            )}
          </fieldset>

          <div className="d-flex w-100 justify-content-center stateRow">
            <fieldset className="d-flex flex-column m-1">
              <label htmlFor="state" className="formLabel">
                State
              </label>
              <select
                name="state"
                id="state"
                className="p-2"
                onChange={handleChange}
              >
                <option value="">Select a state</option>
                {states.map((state, index) => (
                  <option value={state} key={index}>
                    {state}
                  </option>
                ))}
              </select>
              {error && error === "please enter state" ? (
                <p className="text-danger">{error} </p>
              ) : (
                ""
              )}
            </fieldset>
            {!addCity && (
              <fieldset className="d-flex flex-column m-1">
                <label htmlFor="city" className="formLabel">
                  City
                </label>
                <select
                  name="city"
                  id="city"
                  onChange={handleChange}
                  className="p-2"
                >
                  <option value="">Select a city</option>
                  {cities &&
                    cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                </select>
                {error && error === "please enter city" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </fieldset>
            )}

            {addCity && (
              <fieldset className="d-flex flex-column w-50 m-1">
                <label htmlFor="addCity" className="formLabel">
                  Add City
                </label>
                <input
                  type="text"
                  id="addCity"
                  name="addCity"
                  className="w-100 p-2"
                  onChange={handleChange}
                />

                {error && error === "please enter city" ? (
                  <p className="text-danger">{error} </p>
                ) : (
                  ""
                )}
              </fieldset>
            )}
          </div>

          <fieldset>
            <input
              type="checkbox"
              id="addCityCheckbox"
              checked={addCity}
              onChange={handleAddCity}
            />
            <label htmlFor="addCityCheckbox" className="ms-2">
              Add City
            </label>
          </fieldset>

          <div className="d-flex w-100 justify-content-between stateRow">
            <fieldset className=" d-flex flex-column m-1">
              <label htmlFor="address" className="formLabel">
                Address
              </label>
              <input
                className="p-2 "
                type="text"
                id="address"
                name="address"
                onChange={handleChange}
              />
            </fieldset>
            <fieldset className=" d-flex flex-column m-1">
              <label htmlFor="pincode" className="formLabel">
                Pincode
              </label>
              <input
                type="text"
                className="p-2"
                onChange={handleChange}
                id="pincode"
                name="pincode"
              />
              {error && error === "please enter a valid pincode" ? (
                <p className="text-danger">{error}</p>
              ) : (
                ""
              )}
            </fieldset>
          </div>

          <button
            className="submitBtn mx-auto my-3 fw-bold rounded-3"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default AddInstitute;
